import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { signal, Injectable, EventEmitter, inject, DestroyRef, Component, Input, Output, computed, NgModule } from '@angular/core';
import * as i2 from '@garmin-avcloud/avcloud-ui-common/icon';
import { Icons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import * as i3 from '@garmin-avcloud/avcloud-ui-common/link';
import { FlyLinkModule } from '@garmin-avcloud/avcloud-ui-common/link';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { timer } from 'rxjs';
function FlyMessageItemComponent_a_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 7);
    i0.ɵɵtext(1, " Link ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("href", ctx_r0.message.link, i0.ɵɵsanitizeUrl);
  }
}
function FlyMessageItemComponent_div_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵlistener("click", function FlyMessageItemComponent_div_6_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.minimize.emit(ctx_r0.message.id));
    });
    i0.ɵɵelement(1, "fly-icon", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("icon", ctx_r0.Icons.Minus)("flexible", true);
  }
}
function FlyMessageCenterComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 5)(1, "fly-message-item", 6);
    i0.ɵɵlistener("minimize", function FlyMessageCenterComponent_div_1_Template_fly_message_item_minimize_1_listener() {
      const message_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.minimizeMessage(message_r2));
    })("remove", function FlyMessageCenterComponent_div_1_Template_fly_message_item_remove_1_listener() {
      const message_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.removeNewMessage(message_r2.id));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const message_r2 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("message", message_r2);
  }
}
function FlyMessageCenterComponent_div_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 5)(1, "fly-message-item", 7);
    i0.ɵɵlistener("remove", function FlyMessageCenterComponent_div_3_Template_fly_message_item_remove_1_listener() {
      const message_r5 = i0.ɵɵrestoreView(_r4).$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.removeMessage(message_r5.id));
    });
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const message_r5 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("message", message_r5)("saved", true);
  }
}
var MessageType;
(function (MessageType) {
  MessageType[MessageType["ERROR"] = 0] = "ERROR";
  MessageType[MessageType["CAUTION"] = 1] = "CAUTION";
  MessageType[MessageType["SUCCESS"] = 2] = "SUCCESS";
  MessageType[MessageType["INFO"] = 3] = "INFO";
})(MessageType || (MessageType = {}));
class MessageCenterService {
  constructor() {
    this.messageQueue = signal([]);
    this.counter = this.messageQueue().length;
  }
  /**
   *
   * @param messageText The text to display. Aim to keep this short.
   * @param messageType MessageType enum, determines the styling of the notification
   * @param link internal link presented with notification
   * @param timeOut time to automatically minimize in milliseconds, defaults to 10 seconds
   */
  createMessage(messageText, messageType = MessageType.INFO, link, timeOut = 10000) {
    this.messageQueue.update(value => {
      const nextValue = [...value];
      nextValue.push({
        id: this.counter,
        text: messageText,
        type: messageType,
        link,
        timeOut
      });
      return nextValue;
    });
    this.counter += 1;
  }
  static {
    this.ɵfac = function MessageCenterService_Factory(t) {
      return new (t || MessageCenterService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MessageCenterService,
      factory: MessageCenterService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessageCenterService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class FlyMessageItemComponent {
  constructor() {
    this.saved = false;
    this.remove = new EventEmitter();
    this.minimize = new EventEmitter();
    this.Icons = Icons;
    this.MessageType = MessageType;
    this.destroyRef = inject(DestroyRef);
  }
  ngOnInit() {
    if (this.message.timeOut != null && this.message.timeOut > 0) {
      timer(this.message.timeOut).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.minimize.emit(this.message.id));
    }
  }
  static {
    this.ɵfac = function FlyMessageItemComponent_Factory(t) {
      return new (t || FlyMessageItemComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyMessageItemComponent,
      selectors: [["fly-message-item"]],
      inputs: {
        message: "message",
        saved: "saved"
      },
      outputs: {
        remove: "remove",
        minimize: "minimize"
      },
      decls: 10,
      vars: 17,
      consts: [[1, "container"], [1, "content"], ["flyLink", "", "flyLinkType", "internal", 3, "href", 4, "ngIf"], ["class", "button-icon-wrapper minimize-button", 3, "click", 4, "ngIf"], [1, "button-icon-wrapper", 3, "click"], [3, "icon", "flexible"], [1, "chip"], ["flyLink", "", "flyLinkType", "internal", 3, "href"], [1, "button-icon-wrapper", "minimize-button", 3, "click"]],
      template: function FlyMessageItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "p");
          i0.ɵɵtext(3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, FlyMessageItemComponent_a_4_Template, 2, 1, "a", 2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "div");
          i0.ɵɵtemplate(6, FlyMessageItemComponent_div_6_Template, 2, 2, "div", 3);
          i0.ɵɵelementStart(7, "div", 4);
          i0.ɵɵlistener("click", function FlyMessageItemComponent_Template_div_click_7_listener() {
            return ctx.remove.emit(ctx.message.id);
          });
          i0.ɵɵelement(8, "fly-icon", 5);
          i0.ɵɵelementEnd()();
          i0.ɵɵelement(9, "div", 6);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("container--error", ctx.message.type === ctx.MessageType.ERROR)("container--caution", ctx.message.type === ctx.MessageType.CAUTION)("container--success", ctx.message.type === ctx.MessageType.SUCCESS);
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate(ctx.message.text);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.message.link);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", !ctx.saved);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("icon", ctx.Icons.Cancel)("flexible", true);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("chip--error", ctx.message.type === ctx.MessageType.ERROR)("chip--caution", ctx.message.type === ctx.MessageType.CAUTION)("chip--success", ctx.message.type === ctx.MessageType.SUCCESS);
        }
      },
      dependencies: [i1.NgIf, i2.IconComponent, i3.FlyLinkDirective],
      styles: [".container[_ngcontent-%COMP%]{display:flex;width:100%;min-height:3.875rem;background-color:var(--interactive-ui);position:relative;border:.0625rem solid var(--info)}.container[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]:nth-child(2){padding-top:.5rem;padding-right:1rem;display:flex;flex-grow:1;justify-content:flex-end;gap:.25rem}.container--error[_ngcontent-%COMP%]{border-color:var(--error)}.container--caution[_ngcontent-%COMP%]{border-color:var(--caution)}.container--success[_ngcontent-%COMP%]{border-color:var(--success)}p[_ngcontent-%COMP%]{max-width:15rem}.content[_ngcontent-%COMP%]{display:flex;flex-direction:column;padding:.5rem 1rem}.chip[_ngcontent-%COMP%]{display:inline-block;border-top:1rem solid var(--info);border-left:1rem solid var(--interactive-ui);position:absolute;top:0%;right:0%}.chip--error[_ngcontent-%COMP%]{border-top-color:var(--error)}.chip--caution[_ngcontent-%COMP%]{border-top-color:var(--caution)}.chip--success[_ngcontent-%COMP%]{border-top-color:var(--success)}.button-icon-wrapper[_ngcontent-%COMP%]{display:flex;padding:.5625rem;height:1.75rem;width:1.75rem;cursor:pointer}.minimize-button[_ngcontent-%COMP%]{padding-top:.875rem}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyMessageItemComponent, [{
    type: Component,
    args: [{
      selector: 'fly-message-item',
      template: "<div class=\"container\"\n     [class.container--error]=\"message.type === MessageType.ERROR\"\n     [class.container--caution]=\"message.type === MessageType.CAUTION\"\n     [class.container--success]=\"message.type === MessageType.SUCCESS\">\n  <div class=\"content\">\n    <p>{{message.text}}</p>\n    <a *ngIf=\"message.link\"\n      flyLink\n      flyLinkType=\"internal\"\n      [href]=\"message.link\">\n      Link\n    </a>\n  </div>\n  <div>\n    <div *ngIf=\"!saved\"\n          class=\"button-icon-wrapper minimize-button\"\n          (click)=\"minimize.emit(message.id)\">\n      <fly-icon [icon]=\"Icons.Minus\"\n        [flexible]=\"true\">\n      </fly-icon>\n    </div>\n    <div class=\"button-icon-wrapper\"\n        (click)=\"remove.emit(message.id)\">\n      <fly-icon [icon]=\"Icons.Cancel\"\n        [flexible]=\"true\">\n      </fly-icon>\n    </div>\n  </div>\n  <div class=\"chip\"\n       [class.chip--error]=\"message.type === MessageType.ERROR\"\n       [class.chip--caution]=\"message.type === MessageType.CAUTION\"\n       [class.chip--success]=\"message.type === MessageType.SUCCESS\">\n  </div>\n</div>\n",
      styles: [".container{display:flex;width:100%;min-height:3.875rem;background-color:var(--interactive-ui);position:relative;border:.0625rem solid var(--info)}.container>div:nth-child(2){padding-top:.5rem;padding-right:1rem;display:flex;flex-grow:1;justify-content:flex-end;gap:.25rem}.container--error{border-color:var(--error)}.container--caution{border-color:var(--caution)}.container--success{border-color:var(--success)}p{max-width:15rem}.content{display:flex;flex-direction:column;padding:.5rem 1rem}.chip{display:inline-block;border-top:1rem solid var(--info);border-left:1rem solid var(--interactive-ui);position:absolute;top:0%;right:0%}.chip--error{border-top-color:var(--error)}.chip--caution{border-top-color:var(--caution)}.chip--success{border-top-color:var(--success)}.button-icon-wrapper{display:flex;padding:.5625rem;height:1.75rem;width:1.75rem;cursor:pointer}.minimize-button{padding-top:.875rem}\n"]
    }]
  }], null, {
    message: [{
      type: Input
    }],
    saved: [{
      type: Input
    }],
    remove: [{
      type: Output
    }],
    minimize: [{
      type: Output
    }]
  });
})();
class FlyMessageCenterComponent {
  constructor() {
    this.service = inject(MessageCenterService);
    this.Icons = Icons;
    this.collapsed = true;
    this.newMessages = this.service.messageQueue;
    this.messages = signal([]);
    this.hasError = computed(() => {
      return this.messages().some(message => message.type === MessageType.ERROR);
    });
  }
  minimizeMessage(message) {
    this.messages.update(value => {
      const nextValue = [...value];
      nextValue.push(message);
      return nextValue;
    });
    this.removeNewMessage(message.id);
  }
  removeNewMessage(id) {
    this.newMessages.update(value => {
      const nextValue = [...value];
      nextValue.splice(this.newMessages().findIndex(message => message.id === id), 1);
      return nextValue;
    });
  }
  removeMessage(id) {
    return this.messages.update(value => {
      const nextValue = [...value];
      nextValue.splice(value.findIndex(message => message.id === id), 1);
      return nextValue;
    });
  }
  static {
    this.ɵfac = function FlyMessageCenterComponent_Factory(t) {
      return new (t || FlyMessageCenterComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyMessageCenterComponent,
      selectors: [["fly-message-center"]],
      decls: 9,
      vars: 12,
      consts: [["class", "message-item", 4, "ngFor", "ngForOf"], [3, "hidden"], [1, "container"], [1, "error", 3, "icon"], [1, "dropdown-icon", 3, "click", "icon"], [1, "message-item"], [3, "minimize", "remove", "message"], [3, "remove", "message", "saved"]],
      template: function FlyMessageCenterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div");
          i0.ɵɵtemplate(1, FlyMessageCenterComponent_div_1_Template, 2, 1, "div", 0);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵtemplate(3, FlyMessageCenterComponent_div_3_Template, 2, 2, "div", 0);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 2);
          i0.ɵɵtext(5);
          i0.ɵɵelementStart(6, "div");
          i0.ɵɵelement(7, "fly-icon", 3);
          i0.ɵɵelementStart(8, "fly-icon", 4);
          i0.ɵɵlistener("click", function FlyMessageCenterComponent_Template_fly_icon_click_8_listener() {
            return ctx.collapsed = !ctx.collapsed;
          });
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.newMessages());
          i0.ɵɵadvance();
          i0.ɵɵproperty("hidden", ctx.collapsed);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.messages());
          i0.ɵɵadvance();
          i0.ɵɵclassProp("hidden", ctx.messages().length === 0);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" Message Center (", ctx.messages().length, ") ");
          i0.ɵɵadvance(2);
          i0.ɵɵclassProp("hidden", !ctx.hasError());
          i0.ɵɵproperty("icon", ctx.Icons.InvalidHardcodedColor);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("dropdown-icon--flipped", !ctx.collapsed);
          i0.ɵɵproperty("icon", ctx.Icons.SelectDropdown);
        }
      },
      dependencies: [i1.NgForOf, i2.IconComponent, FlyMessageItemComponent],
      styles: [".container[_ngcontent-%COMP%]{display:flex;align-items:center;padding:.5rem;height:2.25rem;min-width:18rem;background-color:var(--interactive-ui)}.container[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]:last-child{display:flex;flex-grow:1;align-items:center;justify-content:right}.dropdown-icon[_ngcontent-%COMP%]{transition-duration:.25s;margin:0 .5rem}.dropdown-icon--flipped[_ngcontent-%COMP%]{transform:rotate(180deg) translateY(.1rem)}.hidden[_ngcontent-%COMP%]{display:none}.message-item[_ngcontent-%COMP%]{padding-bottom:.25rem}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyMessageCenterComponent, [{
    type: Component,
    args: [{
      selector: 'fly-message-center',
      template: "<div>\n  <div *ngFor=\"let message of newMessages()\"\n       class=\"message-item\">\n    <fly-message-item [message]=\"message\"\n                      (minimize)=\"minimizeMessage(message)\"\n                      (remove)=\"removeNewMessage(message.id)\">\n    </fly-message-item>\n  </div>\n</div>\n<div [hidden]=\"collapsed\">\n  <div *ngFor=\"let message of messages()\"\n      class=\"message-item\">\n    <fly-message-item [message]=\"message\"\n                      [saved]=\"true\"\n                      (remove)=\"removeMessage(message.id)\">\n    </fly-message-item>\n  </div>\n</div>\n<div class=\"container\"\n     [class.hidden]=\"messages().length === 0\">\n  Message Center ({{messages().length}})\n  <div>\n    <fly-icon class=\"error\"\n              [icon]=\"Icons.InvalidHardcodedColor\"\n              [class.hidden]=\"!hasError()\">\n    </fly-icon>\n    <fly-icon class=\"dropdown-icon\"\n              [class.dropdown-icon--flipped]=\"!collapsed\"\n              [icon]=\"Icons.SelectDropdown\"\n              (click)=\"collapsed = !collapsed\">\n    </fly-icon>\n  </div>\n</div>\n",
      styles: [".container{display:flex;align-items:center;padding:.5rem;height:2.25rem;min-width:18rem;background-color:var(--interactive-ui)}.container div:last-child{display:flex;flex-grow:1;align-items:center;justify-content:right}.dropdown-icon{transition-duration:.25s;margin:0 .5rem}.dropdown-icon--flipped{transform:rotate(180deg) translateY(.1rem)}.hidden{display:none}.message-item{padding-bottom:.25rem}\n"]
    }]
  }], null, null);
})();
class FlyMessageCenterModule {
  static {
    this.ɵfac = function FlyMessageCenterModule_Factory(t) {
      return new (t || FlyMessageCenterModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyMessageCenterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [MessageCenterService],
      imports: [CommonModule, FlyIconModule, FlyLinkModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyMessageCenterModule, [{
    type: NgModule,
    args: [{
      declarations: [FlyMessageCenterComponent, FlyMessageItemComponent],
      imports: [CommonModule, FlyIconModule, FlyLinkModule],
      exports: [FlyMessageCenterComponent, FlyMessageItemComponent],
      providers: [MessageCenterService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common/message-center
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlyMessageCenterComponent, FlyMessageCenterModule, FlyMessageItemComponent, MessageCenterService, MessageType };
