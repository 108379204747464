import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { environment } from '@environment';
import { AVC_MAPS_DISPLAY_CONFIG, AvcMapsDisplayConfig } from '@garmin-avcloud/avc-maps-display';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';
import { FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import { FlyInputModule } from '@garmin-avcloud/avcloud-ui-common/input';
import { FlyLayoutsModule } from '@garmin-avcloud/avcloud-ui-common/layouts';
import { FlyLoadingSpinnerModule } from '@garmin-avcloud/avcloud-ui-common/loading-spinner';
import { FlyMessageCenterModule } from '@garmin-avcloud/avcloud-ui-common/message-center';
import { FlyNavModule } from '@garmin-avcloud/avcloud-ui-common/nav';
import { FlySelectModule } from '@garmin-avcloud/avcloud-ui-common/select';
import {
  AUTH_GUARD_CONFIG_TOKEN,
  AUTH_SERVICE_CONFIG_TOKEN,
  AuthGuard,
  AuthGuardConfig,
  AuthServiceConfig,
  CAPS_SERVICE_CONFIG_TOKEN,
  CapsServiceConfig,
  CUSTOMER_FREEFORM_SETTING_SERVICE_CONFIG_TOKEN,
  CUSTOMER_OPTION_VALUE_SETTING_SERVICE_CONFIG_TOKEN,
  CUSTOMER_SERVICE_CONFIG_TOKEN,
  CustomerFreeformSettingServiceConfig,
  CustomerOptionValueSettingServiceConfig,
  CustomerServiceConfig,
  HTTP_INTERCEPTOR_CONFIG_TOKEN,
  HttpInterceptorConfig,
  HttpInterceptorService,
  JSON_SYNC_SERVICE_CONFIG_TOKEN,
  JsonSyncServiceConfig,
  SETTING_SERVICE_CONFIG_TOKEN,
  SettingServiceConfig,
  SubscriptionGuardConfig,
  SUBSCRIPTION_SERVICE_CONFIG_TOKEN,
  SubscriptionServiceConfig,
  SUBSCRIPTION_GUARD_CONFIG_TOKEN
} from '@garmin-avcloud/avcloud-web-utils';
import { FeaturesServiceConfig, featuresServiceConfigToken } from '@garmin-avcloud/avcloud-web-utils/feature';
import { TraceService, createErrorHandler } from '@sentry/angular';
import { FeatureFlag } from '@shared/enums/feature-flag.enum';
import { CustomerIdInjectorService } from '@shared/services/customer-id-injector/customer-id-injector.service';
import { SharedModule } from '@shared/shared.module';
import { FilingStatusModule } from '../shared/pipes/filing-status/filing-status.module';
import { FullTemplateComponent } from './components/full-template/full-template.component';
import { AccountOverlayComponent } from './components/full-template/subcomponents/account-overlay/account-overlay.component';
import { FooterComponent } from './components/full-template/subcomponents/footer/footer.component';
import { HeaderComponent } from './components/full-template/subcomponents/header/header.component';
import { SidebarComponent } from './components/full-template/subcomponents/sidebar/sidebar.component';
import { ApiModule } from './modules/api/api.module';
// This function redirects the 'LOCAL'/'DEV' frontend to use 'TEST' backend services
const redirectDevToTest = (
  name: 'LOCAL' | 'DEV' | 'TEST' | 'STAGE' | 'PROD'
): 'TEST' | 'STAGE' | 'PROD' => {
  switch (name) {
    case 'DEV':
    case 'LOCAL':
      return 'TEST';
    default:
      return name;
  }
};

@NgModule({ declarations: [
  AccountOverlayComponent,
  FullTemplateComponent,
  FooterComponent,
  SidebarComponent,
  HeaderComponent
],
exports: [
  FullTemplateComponent
], imports: [ApiModule,
  CommonModule,
  FilingStatusModule,
  FlyButtonModule,
  FlyIconModule,
  FlyInputModule,
  FlyLayoutsModule,
  FlyLoadingSpinnerModule,
  FlyMessageCenterModule,
  FlyNavModule,
  FlySelectModule,
  ReactiveFormsModule,
  RouterModule,
  SharedModule], providers: [
  AuthGuard,
  {
    provide: AVC_MAPS_DISPLAY_CONFIG,
    useValue: {
      clientId: 'AVC_PILOT_WEB_USER',
      mapAssetsPath: 'map-assets',
      uiCommonAssetsPath: 'assets/avcloud-ui-common',
      environment: environment.name,
      capsUrl: `${environment.garmin.aviation.workerApiHost}/caps`,
      chartsUrl: `${environment.garmin.aviation.workerApiHost}/charts`
    } as AvcMapsDisplayConfig
  },
  {
    provide: AUTH_GUARD_CONFIG_TOKEN,
    useValue: {
      redirectRoute: ''
    } as AuthGuardConfig
  },
  {
    provide: AUTH_SERVICE_CONFIG_TOKEN,
    useValue: {
      environment: redirectDevToTest(environment.name),
      clientId: 'AVC_PILOT_WEB_USER'
    } as AuthServiceConfig
  },
  {
    provide: CAPS_SERVICE_CONFIG_TOKEN,
    useValue: {
      baseUrl: 'https://dcaps.dciwx.com/caps',
      subscriptionType: 'com.garmin.phoenix.b2b',
      deviceId: '🧢',
      customerId: '🧢',
      transactionKey: '🧢'
    } as CapsServiceConfig
  },
  {
    provide: CUSTOMER_SERVICE_CONFIG_TOKEN,
    useValue: {
      environment: redirectDevToTest(environment.name),
      clientId: 'AVC_PILOT_WEB_USER'
    } as CustomerServiceConfig
  },
  {
    provide: CUSTOMER_OPTION_VALUE_SETTING_SERVICE_CONFIG_TOKEN,
    useValue: {
      environment: redirectDevToTest(environment.name)
    } as CustomerOptionValueSettingServiceConfig
  },
  {
    provide: CUSTOMER_FREEFORM_SETTING_SERVICE_CONFIG_TOKEN,
    useValue: {
      environment: redirectDevToTest(environment.name)
    } as CustomerFreeformSettingServiceConfig
  },
  {
    provide: ErrorHandler,
    useValue: createErrorHandler({
      showDialog: false
    })
  },
  {
    provide: featuresServiceConfigToken,
    useValue: {
      gatewayUrl: environment.garmin.aviation.gatewayHost,
      serviceApiPath: '/v1/feature-flags',
      features: Object.values(FeatureFlag)
    } as FeaturesServiceConfig
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomerIdInjectorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTOR_CONFIG_TOKEN,
    useValue: {
      ignoreList: [
        // Adding the bearer token is not allowed on these CORS requests
        'https://dcaps.dciwx.com/caps',
        'https://dcache.dciwx.com/caps/',
        'https://static.garmincdn.com/' // ie, stock aircraft images
      ],
      clientId: 'AVC_PILOT_WEB_USER'
    } as HttpInterceptorConfig
  },
  {
    provide: JSON_SYNC_SERVICE_CONFIG_TOKEN,
    useValue: {
      environment: redirectDevToTest(environment.name)
    } as JsonSyncServiceConfig
  },
  {
    provide: TraceService,
    deps: [Router]
  },
  {
    provide: SETTING_SERVICE_CONFIG_TOKEN,
    useValue: {
      environment: redirectDevToTest(environment.name)
    } as SettingServiceConfig
  },
  {
    provide: SUBSCRIPTION_GUARD_CONFIG_TOKEN,
    useValue: {
      redirectRoute: ''
    } as SubscriptionGuardConfig
  },
  {
    provide: SUBSCRIPTION_SERVICE_CONFIG_TOKEN,
    useValue: {
      environment: redirectDevToTest(environment.name)
    } as SubscriptionServiceConfig
  },
  provideHttpClient(withInterceptorsFromDi())
] })
export class CoreModule {
  constructor(
    trace: TraceService,
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule != null) {
      throw new Error('CoreModule has already been loaded. Import CoreModule in AppModule only.');
    }
  }
}
